#loading-page-canvas {
  background-color: var(--UIcolour0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  animation: globalFadeIn ease var(--globalFadeEase);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

#loading-logo-wrapper {
  max-width: 50%;
  margin: auto;
}

.loading-text {
  max-width: 50%;
  font-size: 14px;
  margin-top: 5px;
}

.loading-page-header-canvas {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  animation: globalFadeIn ease var(--globalFadeEase);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.table-loading-canvas {
  max-width: var(--globalPageWidth);
  background-color: var(--UIcolour0);
  width: 100%;
  height: 100%;
  margin-top: 90px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  animation: globalFadeIn ease var(--globalFadeEase);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.success-loading-page-canvas {
  max-width: var(--globalPageWidth);
  background-color: var(--UIcolour0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  animation: globalFadeIn ease var(--globalFadeEase);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.container-loading-canvas {
  background-color: var(--UIcolour0);
  width: auto;
  height: 50px;
  margin: auto;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  animation: globalFadeIn ease var(--globalFadeEase);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.loading-checkmark-icon-wrapper-checked {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 4px;
  background: var(--brandColour1);
  margin-bottom: 10px;
}

.loading-checkmark-icon-checked {
  height: 20px;
  width: 20px;
  fill: white;
}
