.content-list-item-container {
  margin: auto;
  margin-left: 0;
  margin-top: 0;
  max-width: 555px;
}

.content-list-item-heading-and-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 15px;
}

.content-list-item-heading {
  margin-top: -15px;
}

.content-list-item-description {
  font-size: 16px;
  color: var(--UIcolour2);
  text-align: left;
  margin-left: 0px;
  margin-right: 15px;
  margin-top: 0px;
  margin-bottom: auto;
}
